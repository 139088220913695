@tailwind base;

@layer base {
  html {
    @apply text-neutral-800;
  }
  html.dark {
    @apply text-neutral-50;
    @apply bg-neutral-800;
  }
}

@tailwind components;
@tailwind utilities;

@font-face {
  font-family: SolaimanLipi;
  src: url(./assets/fonts/SolaimanLipi.ttf);
}
@font-face {
  font-family: Parisienne;
  src: url(./assets/fonts/Parisienne.ttf);
}
@font-face {
  font-family: PlayfairDisplay;
  src: url(./assets/fonts/PlayfairDisplay.ttf);
}
@font-face {
  font-family: Varela;
  src: url(./assets/fonts/Varela.ttf);
}

/* --------------- Common CSS ------------------- */
/* body{
    font-family: SolaimanLipi;
} */

*{
  scrollbar-width: thin;
  scrollbar-color: #000000 #DFDBDC;
  margin: 0;
  padding: 0;
}
p {
  font-family: Varela, sans-serif;
}
/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: PlayfairDisplay, sans-serif;
} */

input::placeholder {
  color: #191308;
  opacity: 1; /* Firefox */
}

input::-ms-input-placeholder { /* Edge 12-18 */
  color: #191308;
}

body{
  background-color: #341f66 !important;
}

.Corporate_Blue {
  color: #341f66 ;
}
.Corporate_Blue_bg {
  background-color: #341f66 ;
}

.Light_Grey {
  color: #d1d1d1 ;
}
.border_Light_Grey {
  border-color: #d1d1d1 ;
}
.Light_Grey_bg {
  background-color: #d1d1d1 ;
}

.Orange_Yellow {
  color: #f6ae28 ;
}
.Orange_Yellow_bg {
  background-color: #f6ae28 ;
}
.border_Orange_Yellow {
  border-color: #f6ae28 ;
}

.shadow_Orange_Yellow {
  --tw-shadow-color: #f6ae28;
  --tw-shadow: var(--tw-shadow-colored);
}


.Organic_Black {
  color: #191308 ;
}
.Organic_Black_bg {
  background-color: #191308 ;
}

.Accent_Red {
  color: #b80000 ;
}
.Accent_Red_bg {
  background-color: #b80000 ; 
}

/* --------------- Common CSS ------------------- */

/* Navbar css  */

.navbar_main ul li:hover{
  background-color: #d1d1d1 ; 
  color: #191308 ;
  border-radius: 10px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
transition-duration: 0.5s;
}


#lastBtn:hover{
  background-color: transparent !important; 
  color: transparent !important;
  border-radius: 0 !important;
}

/* .active-btn{
  background-color: #d1d1d1; 
  color: #191308 ;
  border-radius: 10px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
transition-duration: 0.5s;
} */

.desktop_navbar li a{
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 0.75rem;
  transition-duration: 300ms;
}
/* .desktop_navbar li a:hover{
  color: #191308;
  background-color: #d1d1d1;
}

.desktop_navbar li .active {
  background-color: #191308; 
  color: #d1d1d1 ;
  padding: 8px 16px;
  border-radius: 10px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
transition-duration: 0.5s;
}
.desktop_navbar li .active:hover {
  background-color: #191308; 
  color: #d1d1d1 ;
} */

/* Navbar css  */

/* swiper Slider css */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-horizontal > .swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
  display: none;
}

/* swiper Slider css */

#basic-addon2 {
  cursor: pointer;
}



.two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.six-lines {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gradient {
  background: linear-gradient(90deg, #0058a2, #4fa0e3);
  background-size: 200% 200%;
}

.element-to-rotate {
  animation: gradientChange 4s ease infinite;
}

.lineSingle {
  background-image: linear-gradient(
    90deg,
    rgba(149, 131, 198, 0) 1.46%,
    rgba(149, 131, 198, 0.6) 40.83%,
    rgba(149, 131, 198, 0.3) 65.57%,
    rgba(149, 131, 198, 0) 107.92%
  );
}

/* header section css  */
/* header section css  */
.header-banner-sec {
  background-image: url("./assets/img/BMC-Header-banner-sec-img.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  transition: all 0.4s ease;
  position: relative;
  top: -65px;
}

.header-banner-sec::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#f6ae28, rgba(251, 190, 36, 0.712));
}
/* header section css  */
/* services section css  */
.services-banner-sec {
  background-image: url("./assets/img/services-banner-sec-img.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  transition: all 0.4s ease;
  position: relative;
  top: -65px;
}

.services-banner-sec::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#f6ae28, rgba(251, 190, 36, 0.712));
}
/* services section css  */

/* feedback css */
#testimonials .swiper-slide img {
  width: 3.5rem;
  height: 3.5rem;
}
/* feedback css */

/* item details css  */

.details-img .swiper-wrapper {
  height: auto;
}
.details-img .swiper {
  height: auto;
}

.details-img .swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.details-img .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.details-img .swiper-slide img {
  display: block;
  width: 100%;
  object-fit: contain;
}

/* item details css  */


/* dotted bg css  */
.dotted-bg {
  background-image: -webkit-repeating-radial-gradient(center center,rgba(0,0,0,0.2),rgba(0,0,0,0.2) 1px,transparent 1px,transparent 100%);
  background-image: -moz-repeating-radial-gradient(center center,rgba(0,0,0,0.2),rgba(0,0,0,0.2) 1px,transparent 1px,transparent 100%);
  background-image: -ms-repeating-radial-gradient(center center,rgba(0,0,0,0.2),rgba(0,0,0,0.2) 1px,transparent 1px,transparent 100%);
  background-image: -o-repeating-radial-gradient(center center,rgba(0,0,0,0.2),rgba(0,0,0,0.2) 1px,transparent 1px,transparent 100%);
  background-image: repeating-radial-gradient(center center,rgba(0,0,0,0.2),rgba(0,0,0,0.2) 1px,transparent 1px,transparent 100%);
  background-size: 5px 5px;
}
/* dotted bg css  */

/* pagination css  */
.css-1gaup4j-MuiButtonBase-root-MuiPaginationItem-root{
  color:white !important;
}
.css-1v2lvtn-MuiPaginationItem-root{
  color: white !important;
}
/* pagination css  */

